import React from 'react';
import '../App.css';
import env from "../env";
import config from "../config";
import { TextField } from '@material-ui/core';
import { Button } from '@mui/material';
import Swal from "sweetalert2";

export default class Login extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      invalidLogin: false,
      loadingState: config.LoadingStates.loading,
      login: {
        username: '',
        password: '',
      }
    };
    this.handleChange = this.handleChange.bind(this);
    this.login = this.login.bind(this);
    this.isInvalidInput = this.isInvalidInput.bind(this);
  }

  componentDidMount() {
    this.setState({
      ...this.state,
      loadingState: config.LoadingStates.loaded,
      login: {
        username: '',
        password: '',
        casa: '',
      }
    });
  };

  isInvalidInput = () => {
    let { login } = this.state;
    let result = '';
    if (!login.casa || login.casa < 0) {
      if (result.length) { result += '; ' }
      result += 'casa'
    }
    if (!login.username || !login.username.length) {
      if (result.length) { result += '; ' }
      result += 'utilizatorul'
    }
    if (!login.password || !login.password.length) {
      if (result.length) { result += '; ' }
      result += 'parola'
    }
    return result;
  }

  login = () => {
    let isInvalidInputResult = this.isInvalidInput();
    if (isInvalidInputResult) {
      Swal.fire({
        title: 'Eroare',
        text: "Vă rugăm să introduceți " + isInvalidInputResult,
        icon: 'error',
      });
      return;
    }

    this.setState({
      ...this.state,
      invalidLogin: false,
      loadingState: config.LoadingStates.loading,
    });
    let loginData = {
      username: this.state.login.username,
      password: this.state.login.password,
    }

    let loginReq = JSON.stringify(loginData);

    let url = env.ApiBaseUrl + "/operators/login";
    fetch(url, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        'cache-control': "no-cache"
      }),

      body: loginReq,
    }).then(res => res.json())
      .then(
        (result) => {
          result.casa = this.state.login.casa;
          this.props.onLogin(result);
        },
        (error) => {
          console.log(error)
          this.setState({
            ...this.state,
            invalidLogin: true,
            loadingState: config.LoadingStates.loaded,
          });
        }
      );
  }

  handleChange = (event, location) => {
    let { login } = this.state;
    login[location] = event.target.value;

    this.setState({
      ...this.state,
      login: login,
    });
  };

  render() {
    let { login, invalidLogin } = this.state;
    let invalidLoginMessage = '';
    if (invalidLogin) {
      invalidLoginMessage = 'Autentificarea a eșuat, vă rugăm să încercați din nou'
    }
    return (
      <div>
        <h1 className="title">Autentificare</h1>
        {this.state.loadingState === config.LoadingStates.loading && <tr><td>Se încarcă datele...</td></tr>}
        {this.state.loadingState !== config.LoadingStates.loading &&
          <div className='center'>
            <TextField
              className="c-manager"
              type="number"
              label="Casa"
              value={login.casa}
              size="small"
              onChange={e => this.handleChange(e, "casa")} />
            <br />
            <TextField
              className="c-manager"
              label="Utilizator"
              value={login.username}
              size="small"
              onChange={e => this.handleChange(e, "username")} />
            <br />
            <TextField
              className="c-manager"
              type="password"
              label="Parolă"
              value={login.password}
              size="small"
              onChange={e => this.handleChange(e, "password")} />
            <br />
            <div className='red'>{invalidLoginMessage}</div>
            <br />
            <Button className="c-button" variant="outlined" onClick={this.login}>Login </Button>
          </div>
        }
      </div>
    );
  }
}

