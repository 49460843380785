import React, { Component } from "react";
import config from "./config";
import Login from "./login/Login";
import MainContribuitors from "./contributors/MainContribuitors";
import logo from "./SIGLA.png";


export default class MainPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            displayingPage: config.DisplayingPage.login,
            currentOperator: {},
        };

        this.onLogin = this.onLogin.bind(this);
        this.logout = this.logout.bind(this);
    }

    onLogin(currentOperator) {
        if (currentOperator) {
            this.setState({
                ...this.state,
                currentOperator: currentOperator,
                displayingPage: config.DisplayingPage.view,
            });
        }
    };

    logout() {
        this.setState({
            ...this.state,
            currentOperator: {},
            displayingPage: config.DisplayingPage.login,
        })
    };

    render() {
        let { displayingPage, currentOperator } = this.state;
        return (
            <div className="main-div">
                <img src={logo} className="logo" alt="logo" />
                {displayingPage === config.DisplayingPage.login && <Login onLogin={this.onLogin} />}
                {displayingPage === config.DisplayingPage.view &&
                    <MainContribuitors
                        logout={this.logout}
                        currentOperator={currentOperator}
                    />}
            </div>
        );
    };
}