import React, { Component } from "react";
import env from "../env";
import config from "../config";
import Swal from "sweetalert2";
import {
    Button,
    Table,
    TableBody,
    TableCell,
    TableRow
} from '@mui/material';
import contributorsFunctions from "./contributorsGeneric";
import '../../node_modules/font-awesome/css/font-awesome.min.css';

export default class ContribuitorsViewer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loadingState: config.LoadingStates.loading,
            currentIndex: -1,
            contributors: [],
            currentContributor: {},
            searchById: '',
            searchByCnp: '',
        };
        this.onIdInputChanged = this.onIdInputChanged.bind(this);
        this.onCnpInputChanged = this.onCnpInputChanged.bind(this);
        this.onSearchById = this.onSearchById.bind(this);
        this.onSearchByCnp = this.onSearchByCnp.bind(this);
    }

    onIdInputChanged(e) {
        this.setState({
            ...this.state,
            searchById: e.target.value
        })
    }

    onCnpInputChanged(e) {
        this.setState({
            ...this.state,
            searchByCnp: e.target.value
        })
    }

    onSearchById() {
        if (!this.state.searchById) {
            Swal.fire({
                title: 'Eroare',
                text: "Vă rugăm să introduceți un ID!",
                icon: 'error',
            });
            return;
        }
        let url = env.ApiBaseUrl + "users/id/" + this.state.searchById;
        this.onSearch(url);
    }

    onSearchByCnp() {
        if (!this.state.searchByCnp) {
            Swal.fire({
                title: 'Eroare',
                text: "Vă rugăm să introduceți un CNP!",
                icon: 'error',
            });
            return;
        }
        let url = env.ApiBaseUrl + "users/cnp/" + this.state.searchByCnp;
        this.onSearch(url);
    }

    onSearch(url) {
        this.setState({
            ...this.state,
            loadingState: config.LoadingStates.loading
        });
        fetch(url, {
            method: 'GET',
            headers: new Headers({
                'cache-control': "no-cache"
            })
        })
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.id) {
                        this.setState({
                            ...this.state,
                            loadingState: config.LoadingStates.loaded,
                            currentContributor: result,
                            searchById: result.id,
                            searchByCnp: result.cnp,
                            searchByName: result.name,
                        })
                    } else {
                        this.setState({
                            ...this.state,
                            loadingState: config.LoadingStates.error,
                        })
                    }
                },
                (error) => {
                    console.log("Eroare: ", error);
                    this.setState({
                        ...this.state,
                        loadingState: config.LoadingStates.error,
                    })
                }
            );
    }

    componentDidMount() {
        if (this.props.editContributer && this.props.editContributer.id) {
            this.setState({
                ...this.state,
                loadingState: config.LoadingStates.loading,
                searchById: this.props.editContributer.id,
            }, () => this.onSearchById());
        } else {
            this.setState({
                ...this.state,
                loadingState: config.LoadingStates.loaded,
            });
        }
    };

    render() {
        let { currentContributor } = this.state;
        const statusString = contributorsFunctions.getStatus(currentContributor.status);
        const searchArea =
            <TableBody>
                <TableRow width="1000">
                    <TableCell className="left-td">
                        <label>ID:&nbsp;</label>
                    </TableCell>
                    <TableCell className="flex">
                        <input
                            readOnly={false}
                            value={this.state.searchById}
                            maxLength={50}
                            onChange={e => this.onIdInputChanged(e)}
                        />
                        &nbsp;
                        <Button
                            size="small"
                            variant="outlined"
                            onClick={this.onSearchById}>
                            <i className="fa fa-search"></i>
                        </Button>
                    </TableCell>
                    <TableCell className="left-td">
                        <label>CNP:&nbsp;</label>
                    </TableCell>
                    <TableCell className="flex">
                        <input
                            readOnly={false}
                            value={this.state.searchByCnp}
                            maxLength={13}
                            onChange={e => this.onCnpInputChanged(e)}
                        />
                        &nbsp;
                        <Button
                            size="small"
                            variant="outlined"
                            onClick={this.onSearchByCnp}>
                            <i className="fa fa-search"></i>
                        </Button>
                    </TableCell>
                </TableRow>
            </TableBody>
        const loadedPage =
            <TableBody>
                <TableRow>
                    <TableCell className="left-td">
                        <label>Nume:&nbsp;</label>
                    </TableCell>
                    <TableCell>
                        {currentContributor.name}
                    </TableCell>
                    <TableCell className="left-td">
                        <label>Status:&nbsp;</label>
                    </TableCell>
                    <TableCell>
                        {statusString}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className="left-td">
                        <label>Adresă:&nbsp;</label>
                    </TableCell>
                    <TableCell colSpan={3}>
                        {currentContributor.address}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className="left-td">
                        <label>0d1. Data nașterii:&nbsp;</label>
                    </TableCell>
                    <TableCell>
                        {currentContributor.dataNasterii}
                    </TableCell>
                    <TableCell className="left-td">
                        <label>1d2. Nr. cupon pensie:&nbsp;</label>
                    </TableCell>
                    <TableCell>
                        {currentContributor.nrCupon}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className="left-td">
                        <label>2r3. Pensie lunară:&nbsp;</label>
                    </TableCell>
                    <TableCell>
                        {currentContributor.pensionAmmount}
                    </TableCell>
                    <TableCell className="left-td">
                        <label>3D4. Data înscrierii în CARP:&nbsp;</label>
                    </TableCell>
                    <TableCell>
                        {currentContributor.registrationDate}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className="left-td">
                        <label>4r5. Nr casă:&nbsp;</label>
                    </TableCell>
                    <TableCell>
                        {currentContributor.officeNumber}
                    </TableCell>
                    <TableCell className="left-td">
                        <label>5r11. Telefon:&nbsp;</label>
                    </TableCell>
                    <TableCell>
                        {currentContributor.phoneNumber}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className="left-td">
                        <label>6dx1. Data plății imprimate:&nbsp;</label>
                    </TableCell>
                    <TableCell>
                        {currentContributor.dataPlatiiImprimate}
                    </TableCell>
                    <TableCell className="left-td">
                        <label>7r10. Cost imprimate:&nbsp;</label>
                    </TableCell>
                    <TableCell>
                        {currentContributor.costImprimate}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className="left-td">
                        <label>8D6. Până când s-a plătit cotizația:&nbsp;</label>
                    </TableCell>
                    <TableCell>
                        {currentContributor.cotisationPayedUntil}
                    </TableCell>
                    <TableCell className="left-td">
                        <label>9D7. Data incasării ultimei cotizații:&nbsp;</label>
                    </TableCell>
                    <TableCell>
                        {currentContributor.cotisationLastDate}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className="left-td">
                        <label>10r8. Cotizația încasată casă:&nbsp;</label>
                    </TableCell>
                    <TableCell>
                        {currentContributor.cotisation_c}
                    </TableCell>
                    <TableCell className="left-td">
                        <label>11r9. Cotizația încasată bancă:&nbsp;</label>
                    </TableCell>
                    <TableCell>
                        {currentContributor.cotisation_b}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className="left-td">
                        <label>12r12. Sold cotizație:&nbsp;</label>
                    </TableCell>
                    <TableCell>
                        {currentContributor.cotisationAmmount}
                    </TableCell>
                    <TableCell className="left-td">
                        <label>13. Suma contribuții casă:&nbsp;</label>
                    </TableCell>
                    <TableCell>
                        {currentContributor.contribution_c}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className="left-td">
                        <label>14. Suma contribuții bancă:&nbsp;</label>
                    </TableCell>
                    <TableCell>
                        {currentContributor.contribution_b}
                    </TableCell>
                    <TableCell className="left-td">
                        <label>15D14. Până când s-au plătit contribuții:&nbsp;</label>
                    </TableCell>
                    <TableCell>
                        {currentContributor.contributionPayedUntil}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className="left-td">
                        <label>16D15. Data ultimei încasări contribuții:&nbsp;</label>
                    </TableCell>
                    <TableCell>
                        {currentContributor.contributionPaymentDate}
                    </TableCell>
                    <TableCell className="left-td">
                        <label>17r16. Încasat FAD casă:&nbsp;</label>
                    </TableCell>
                    <TableCell>
                        {currentContributor.fadammount_c}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className="left-td">
                        <label>18r17. Încasat FAD bancă:&nbsp;</label>
                    </TableCell>
                    <TableCell>
                        {currentContributor.fadammount_b}
                    </TableCell>
                    <TableCell className="left-td">
                        <label>19rx3. Total Încasari FAD:&nbsp;</label>
                    </TableCell>
                    <TableCell>
                        {currentContributor.fadammountTotal}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className="left-td">
                        <label>Dobândă deces:&nbsp;</label>
                    </TableCell>
                    <TableCell>
                        {currentContributor.dobandaDeces}
                    </TableCell>
                    <TableCell className="left-td">
                        <label>20rx1. Încasat Aj. social (casă):&nbsp;</label>
                    </TableCell>
                    <TableCell>
                        {currentContributor.ajammount_c}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className="left-td">
                        <label>21rz1. Încasat Aj. social (bancă):&nbsp;</label>
                    </TableCell>
                    <TableCell>
                        {currentContributor.ajammount_b}
                    </TableCell>
                    <TableCell className="left-td">
                        <label>22rx5. Total Aj. social:&nbsp;</label>
                    </TableCell>
                    <TableCell>
                        {currentContributor.ajammountTotal}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className="left-td">
                        <label>23rx2. Încasat cultural (casă):&nbsp;</label>
                    </TableCell>
                    <TableCell>
                        {currentContributor.culturalAmmount_c}
                    </TableCell>
                    <TableCell className="left-td">
                        <label>24ry2. Încasat cultural (bancă):&nbsp;</label>
                    </TableCell>
                    <TableCell>
                        {currentContributor.culturalAmmount_b}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className="left-td">
                        <label>25rx6. Total cultural:&nbsp;</label>
                    </TableCell>
                    <TableCell>
                        {currentContributor.culturalAmmountTotal}
                    </TableCell>
                    <TableCell className="left-td">
                        <label>26r18. Cheltuieli administrative (casă):&nbsp;</label>
                    </TableCell>
                    <TableCell>
                        {currentContributor.administrativeAmmount_c}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className="left-td">
                        <label>27ry3. Cheltuieli administrative (bancă):&nbsp;</label>
                    </TableCell>
                    <TableCell>
                        {currentContributor.administrativeAmmount_b}
                    </TableCell>
                    <TableCell className="left-td">
                        <label>28rx4. Total cheltuieli administrative:&nbsp;</label>
                    </TableCell>
                    <TableCell>
                        {currentContributor.administrativeAmmountTotal}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className="left-td">
                        <label>Penalizare:&nbsp;</label>
                    </TableCell>
                    <TableCell>
                        {currentContributor.penalizare}
                    </TableCell>
                    <TableCell className="left-td">
                        <label>Dobândă retragere:&nbsp;</label>
                    </TableCell>
                    <TableCell>
                        {currentContributor.dobandaRetragere}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className="left-td red">
                        <label>29D20aaaa. Data acordării împrumutului:&nbsp;</label>
                    </TableCell>
                    <TableCell className="red">
                        {currentContributor.loanStartDate}
                    </TableCell>
                    <TableCell className="left-td red">
                        <label>30r21. Împrumutul acordat:&nbsp;</label>
                    </TableCell>
                    <TableCell className="red">
                        {currentContributor.loanAmmount}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className="left-td red">
                        <label>31r22. Nr. rate lunare:&nbsp;</label>
                    </TableCell>
                    <TableCell className="red">
                        {currentContributor.loanMonthsCount}
                    </TableCell>
                    <TableCell className="left-td red">
                        <label>32r23. Valoarea unei rate:&nbsp;</label>
                    </TableCell>
                    <TableCell className="red">
                        {currentContributor.monthlyLoanPayment}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className="left-td red">
                        <label>33D24. Data lichidării împrumutului:&nbsp;</label>
                    </TableCell>
                    <TableCell className="red">
                        {currentContributor.loanLiquidationDate}
                    </TableCell>
                    <TableCell className="left-td red">
                        <label>34r25. Dobânda anuală percepută %:&nbsp;</label>
                    </TableCell>
                    <TableCell className="red">
                        {currentContributor.yearlyInterestRate}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className="left-td red">
                        <label>35r26. Dobânda reținută:&nbsp;</label>
                    </TableCell>
                    <TableCell className="red">
                        {currentContributor.recievedInterest}
                    </TableCell>
                    <TableCell className="left-td red">
                        <label>36ry1. Dobândă eșalonată:&nbsp;</label>
                    </TableCell>
                    <TableCell className="red">
                        {currentContributor.floatingInterest}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className="left-td red">
                        <label>37D27. Data ultimei restituiri:&nbsp;</label>
                    </TableCell>
                    <TableCell className="red">
                        {currentContributor.lastLoanPaymentDate}
                    </TableCell>
                    <TableCell className="left-td red">
                        <label>38r28. Restituit împrumut (casă):&nbsp;</label>
                    </TableCell>
                    <TableCell className="red">
                        {currentContributor.returnedLoanAmmount_c}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className="left-td red">
                        <label>39r29. Restituit împrumut (bancă):&nbsp;</label>
                    </TableCell>
                    <TableCell className="red">
                        {currentContributor.returnedLoanAmmount_b}
                    </TableCell>
                    <TableCell className="left-td red">
                        <label>40D30. Data încasării rate precedente:&nbsp;</label>
                    </TableCell>
                    <TableCell className="red">
                        {currentContributor.previousLoanPaymentDate}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className="left-td red">
                        <label>41r31. Dobânda recalculată:&nbsp;</label>
                    </TableCell>
                    <TableCell className="red">
                        {currentContributor.recalculatedInterestRate}
                    </TableCell>
                    <TableCell className="left-td red">
                        <label>42r32. Sold împrumut:&nbsp;</label>
                    </TableCell>
                    <TableCell className="red">
                        {currentContributor.loanBalance}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className="left-td">
                        <label>43rz2. Diferență dobândă:&nbsp;</label>
                    </TableCell>
                    <TableCell>
                        {currentContributor.interestDifference}
                    </TableCell>
                    <TableCell className="left-td">
                        <label>44s44. Balneo:&nbsp;</label>
                    </TableCell>
                    <TableCell>
                        {currentContributor.balneo}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className="left-td">
                        <label>45s45. Stomatologie:&nbsp;</label>
                    </TableCell>
                    <TableCell>
                        {currentContributor.stomatologie}
                    </TableCell>
                    <TableCell className="left-td">
                        <label>46s46. Alte ajutoare:&nbsp;</label>
                    </TableCell>
                    <TableCell>
                        {currentContributor.other}
                    </TableCell>
                </TableRow>
            </TableBody>

        let { role } = this.props.currentOperator;
        let operatorButtonText = (role === 'admin') ? "Administrează operatori" : "Administrează contul";

        return (
            <div>
                <h1 className="title">Vizualizări - casa {this.props.currentOperator.casa}</h1>
                <Button className="c-button" variant="outlined" onClick={() => this.props.showPage(config.DisplayingPage.operator)}>{operatorButtonText}</Button>
                <Button className="c-button" variant="outlined" onClick={() => this.props.showPage(config.DisplayingPage.addEdit)}>Adaugă contribuitor nou</Button>
                {currentContributor.id &&
                    <Button className="c-button" variant="outlined" onClick={() => this.props.showPage(config.DisplayingPage.addEdit, currentContributor)}>Editează contribuitorul </Button>}
                {currentContributor.id &&
                    <Button className="c-button" variant="outlined" onClick={() => this.props.showPage(config.DisplayingPage.transactions, currentContributor)}>Tranzacții </Button>}
                <Button className="c-button" variant="outlined" onClick={() => this.props.showPage(config.DisplayingPage.situations)}>Situații</Button>
                <Button className="c-button" variant="outlined" onClick={() => this.props.showPage(config.DisplayingPage.list)}>Listări</Button>
                <Button className="c-button" variant="outlined" color='warning' onClick={this.props.logout}>Deconectați-vă</Button>
                {this.state.loadingState === config.LoadingStates.loading && <tr><td>Se încarcă datele...</td></tr>}
                {this.state.loadingState !== config.LoadingStates.loading &&
                    <Table
                        sx={{ width: 1200 }}
                        size={'small'}>
                        {searchArea}
                        {this.state.loadingState === config.LoadingStates.loaded && currentContributor.id && loadedPage}
                        {this.state.loadingState === config.LoadingStates.error && <tr><td>utilizatorul nu există</td></tr>}
                        {this.state.loadingState === config.LoadingStates.error && <tr><td><Button className="c-button" variant="outlined" color='warning' onClick={this.props.logout}>Autentificați-vă din nou</Button></td></tr>}
                    </Table>
                }
            </div>
        );
    };
}