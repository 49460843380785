import React, { Component } from "react";
import env from "../env";
import config from "../config";
import contribuitorsListsColumns from "./contribuitorsListsColumns";

import {
    Box,
    Button,
} from '@mui/material';
import {
    DataGrid,
} from '@mui/x-data-grid';
import {
    TextField
} from '@material-ui/core';

import '../../node_modules/font-awesome/css/font-awesome.min.css';

const listsCategory = {
    none: 0,
    r: 1,
    d: 2,
    m: 3,
    e: 4,
    listeScoși: 5,
    liste: 6,
    listeToți: 7,
    listeSfAn: 8,
};

export default class ContribuitorsLists extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loadingState: config.LoadingStates.loading,
            data: [],
            settings: {
                date: new Date().toISOString().substring(0, 10),
                name: '',
                address: '',
                registrationDate: '',
                rdmeYear: new Date().getFullYear(),
                minPension: '',
                maxPension: '',
            },
            listsView: listsCategory.none,
        };

        this.getLists = this.getLists.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.viewCategory = this.viewCategory.bind(this);
    }

    componentDidMount() {
        this.setState({
            ...this.state,
            loadingState: config.LoadingStates.loaded,
        });
    };

    getLists(toDisplay) {
        let url = env.ApiBaseUrl + "users/all";//// new apis
        switch (toDisplay) {
            case listsCategory.r:
            case listsCategory.d:
            case listsCategory.m:
            case listsCategory.e:
                url = env.ApiBaseUrl + "users/status/" + toDisplay + "/" + this.state.settings.rdmeYear;
                break;
            case listsCategory.listeScoși:
                url = env.ApiBaseUrl + "users/status/" + this.state.settings.rdmeYear;
                break;
        }

        fetch(url, {
            method: 'GET',
            headers: new Headers({
                'cache-control': "no-cache"
            })
        })
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        ...this.state,
                        loadingState: config.LoadingStates.loaded,
                        data: result,
                    })
                },
                (error) => {
                    this.setState({
                        ...this.state,
                        loadingState: config.LoadingStates.error,
                    })
                }
            );
    }

    handleChange = (event, location) => {
        let { settings } = this.state;
        settings[location] = event.target.value;

        this.setState({
            ...this.state,
            settings: settings,
        });
    };

    viewCategory = (toDisplay) => {
        this.setState({
            ...this.state,
            listsView: toDisplay,
            loadingState: config.LoadingStates.loading
        });
        this.getLists(toDisplay);
    }

    render() {
        let { settings, listsView, loadingState } = this.state;

        const topPage =
            <form autoComplete="off" >
                <Button className="c-button" variant="outlined" color='secondary' onClick={() => this.props.showPage(config.DisplayingPage.view)}>Înapoi </Button>
                <br />Filtre:<br />
                <TextField
                    className="c-view-config"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    label="Data"
                    value={settings.date}
                    size="small"
                    onChange={e => this.handleChange(e, "date")} />
                <TextField
                    className="c-view-config"
                    label="Porțiuni din nume"
                    value={settings.name}
                    size="small"
                    onChange={e => this.handleChange(e, "name")} />
                <TextField
                    className="c-view-config"
                    label="Porțiuni din adresă"
                    value={settings.address}
                    size="small"
                    onChange={e => this.handleChange(e, "address")} />
                <TextField
                    className="c-view-config"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    label="Data înscrierii"
                    value={settings.registrationDate}
                    size="small"
                    onChange={e => this.handleChange(e, "registrationDate")} />
                <TextField
                    className="c-view-config"
                    type="number"
                    label="Pensie minimă"
                    value={settings.minPension}
                    size="small"
                    onChange={e => this.handleChange(e, "minPension")} />
                <TextField
                    className="c-view-config"
                    type="number"
                    label="Pensie maximă"
                    value={settings.maxPension}
                    size="small"
                    onChange={e => this.handleChange(e, "maxPension")} />
                <br />
                <Button className="c-button" variant="outlined" onClick={() => this.viewCategory(listsCategory.liste)}>Listează pensionarii cu filtre </Button>
                <Button className="c-button" variant="outlined" onClick={() => this.viewCategory(listsCategory.listeToți)}>Listează toți pensionarii din evidență </Button>
                <Button className="c-button" variant="outlined" onClick={() => this.viewCategory(listsCategory.listeSfAn)}>Listează toți pensionarii la sfârșit de an </Button>
                <br />
                <Button className="c-button" variant="outlined" onClick={() => this.viewCategory(listsCategory.listeScoși)}>RDME </Button>
                <Button className="c-button" variant="outlined" onClick={() => this.viewCategory(listsCategory.r)}>R </Button>
                <Button className="c-button" variant="outlined" onClick={() => this.viewCategory(listsCategory.d)}>D</Button>
                <Button className="c-button" variant="outlined" onClick={() => this.viewCategory(listsCategory.m)}>M </Button>
                <Button className="c-button" variant="outlined" onClick={() => this.viewCategory(listsCategory.e)}>E </Button>
                <TextField
                    className="c-view-config"
                    type="number"
                    label="Anul"
                    value={settings.rdmeYear}
                    size="small"
                    onChange={e => this.handleChange(e, "rdmeYear")} />
                <br />
                <br />
            </form>

        let columnsToDisplay = [];
        let extraTitle = '';

        switch (listsView) {
            case listsCategory.none:
                columnsToDisplay = contribuitorsListsColumns.none;
                break;
            case listsCategory.liste:
                columnsToDisplay = contribuitorsListsColumns.liste;
                break;
            case listsCategory.listeScoși:
                columnsToDisplay = contribuitorsListsColumns.liste;
                break;
            case listsCategory.listeToți:
                columnsToDisplay = contribuitorsListsColumns.listeToți;
                break;
            case listsCategory.listeSfAn:
                columnsToDisplay = contribuitorsListsColumns.liste;
                break;
            case listsCategory.r:
                columnsToDisplay = contribuitorsListsColumns.liste;
                break;
            case listsCategory.d:
                columnsToDisplay = contribuitorsListsColumns.liste;
                break;
            case listsCategory.m:
                columnsToDisplay = contribuitorsListsColumns.liste;
                break;
            case listsCategory.e:
                columnsToDisplay = contribuitorsListsColumns.liste;
                break;
            default:
                console.log(`Wrong ${listsView}.`);
        }

        return (
            <div>
                <h1 className="title">Listări {extraTitle}</h1>
                {loadingState === config.LoadingStates.error &&
                    <div>
                        A apărut o eroare!
                        <br />
                        <Button className="c-button" variant="outlined" color='warning' onClick={this.props.logout}>Autentificați-vă din nou</Button>
                    </div>}
                {loadingState === config.LoadingStates.loading &&
                    <div>Se încarcă datele...</div>}
                {loadingState === config.LoadingStates.loaded && topPage}
                {loadingState === config.LoadingStates.loaded && listsView !== listsCategory.none && <Box sx={{ height: 380, width: '100%' }}>
                    <DataGrid
                        rowHeight={25}
                        rows={this.state.data}
                        columns={columnsToDisplay}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 40,
                                },
                            },
                            sorting: {
                                sortModel: [{ field: 'name' }],
                            },
                        }}
                        pageSizeOptions={config.DataGrid.PageSizeOptions}
                    />
                </Box>}
            </div>
        );
    };
}