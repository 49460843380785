const contribuitorsListsColumns = {
    none: [],
    liste: [
        { field: 'id', headerName: 'ID', width: 30 },
        { field: 'name', headerName: 'Nume', width: 150 },
        { field: 'address', headerName: 'Domiciliu', width: 300 },
        { field: 'dataNasterii', headerName: 'Data nașterii', width: 120 },
        { field: 'nrCupon', headerName: 'Nr. Cupon', width: 90 },
        { field: 'pensionAmmount', headerName: 'P. lunară', width: 90 },
        { field: 'registrationDate', headerName: 'Data înscrierii', width: 120 },
        { field: 'officeNumber', headerName: 'Nr. casă', width: 90 },
        { field: 'phoneNumber', headerName: 'Telefon', width: 120 },
        { field: 'dx', headerName: '6.dx1 ???', width: 90 },
    ],
    listeToți: [
        { field: 'id', headerName: 'ID', width: 30 },
        { field: 'name', headerName: 'Nume', width: 150 },
        { field: 'address', headerName: 'Domiciliu', width: 300 },
        { field: 'dataNasterii', headerName: 'Data nașterii', width: 120 },
        { field: 'nrCupon', headerName: 'Nr. Cupon', width: 90 },
        { field: 'pensionAmmount', headerName: 'P. lunară', width: 90 },
        { field: 'registrationDate', headerName: 'Data înscrierii', width: 120 },
        { field: 'officeNumber', headerName: 'Nr. casă', width: 90 },
        { field: 'phoneNumber', headerName: 'Telefon', width: 120 },
        { field: 'dataPlatiiImprimate', headerName: '6. D. Carnet', width: 120 },
        { field: 'costImprimate', headerName: '7. Carnet', width: 90 },
        { field: 'cotisationPayedUntil', headerName: '8. d6', width: 120 },
        { field: 'cotisationLastDate', headerName: '9. d7', width: 120 },
        { field: 'cotisation_c', headerName: '10. Casă', width: 90 },
        { field: 'cotisation_b', headerName: '11. Bancă', width: 90 },
        { field: 'cotisationAmmount', headerName: '12. Sold cotiz', width: 120 },
        { field: 'contributionPayedUntil', headerName: '15. D14', width: 120 },
        { field: 'contributionPaymentDate', headerName: '16. D15', width: 120 },
        { field: 'fadammount_c', headerName: '17. fad c.', width: 90 },
        { field: 'fadammount_b', headerName: '18. fad b.', width: 90 },
        { field: 'fadammountTotal', headerName: '19. t. fad', width: 90 },
        { field: 'ajammount_c', headerName: '20. san. c.', width: 90 },
        { field: 'ajammount_b', headerName: '21. san. b.', width: 90 },
        { field: 'ajammountTotal', headerName: '22. t. san', width: 90 },
        { field: 'culturalAmmount_c', headerName: '23. cult. c', width: 90 },
        { field: 'culturalAmmount_b', headerName: '24. cult. b', width: 90 },
        { field: 'culturalAmmountTotal', headerName: '25. t. cult', width: 90 },
        { field: 'administrativeAmmount_c', headerName: '26. adm. c', width: 90 },
        { field: 'administrativeAmmount_b', headerName: '27. adm. b', width: 90 },
        { field: 'administrativeAmmountTotal', headerName: '28. t. adm', width: 90 },
        { field: 'loanStartDate', headerName: '29. d20', width: 120 },
        { field: 'loanAmmount', headerName: '30. imp. ac.', width: 90 },
        { field: 'loanMonthsCount', headerName: '31. nr rate', width: 90 },
        { field: 'monthlyLoanPayment', headerName: '32. val rate', width: 90 },
        { field: 'loanLiquidationDate', headerName: '33. d24', width: 120 },
        { field: 'yearlyInterestRate', headerName: '34. dob%', width: 90 },
        { field: 'recievedInterest', headerName: '35. d. ret.', width: 90 },
        { field: 'floatingInterest', headerName: '36. d. eșal.', width: 90 },
        { field: 'lastLoanPaymentDate', headerName: '37. d27', width: 120 },
        { field: 'returnedLoanAmmount_c', headerName: '38. rest. c.', width: 90 },
        { field: 'returnedLoanAmmount_b', headerName: '39. rest. b.', width: 90 },
        { field: 'previousLoanPaymentDate', headerName: '40. d30', width: 120 },
        { field: 'recalculatedInterestRate', headerName: '41. d. rec.', width: 90 },
        { field: 'loanBalance', headerName: '42. sold împ.', width: 90 },
        { field: 'interestDifference', headerName: '43. dif. dob.', width: 90 },
        { field: 'balneo', headerName: '44. balneo', width: 120 },
        { field: 'stomatologie', headerName: '45. stoma', width: 90 },
        { field: 'other', headerName: '46. alte', width: 90 },
        { field: 'operator', headerName: 'operator', width: 90 },

    ],
}
export default contribuitorsListsColumns;