const contributorsFunctions = {
    getStatus(status) {
        switch (status) {
            case 0:
                return 'Activ';
            case 1:
                return 'Retras';
            case 2:
                return 'Decedat';
            case 3:
                return 'Mutat';
            case 4:
                return 'Exclus';
            default:
                return 'Nedefinit';
        }
    },
    getRezumat(rezumat) {
        let result = [
            { r: 'Cost carnet', v: rezumat.costCarnet },
            { r: 'Cotizație (casă)', v: rezumat.cotizatie_c },
            { r: 'Cotizație (bancă)', v: rezumat.cotizatie_b },
            { r: 'FAD (casă)', v: rezumat.fad_c },
            { r: 'FAD (bancă)', v: rezumat.fad_b },
            { r: 'Sănătate (casă)', v: rezumat.aj_c },
            { r: 'Sănătate (bancă)', v: rezumat.aj_b },
            { r: 'Cultural (casă)', v: rezumat.cultural_c },
            { r: 'Cultural (bancă)', v: rezumat.cultural_b },
            { r: 'Administrativ (casă)', v: rezumat.adm_c },
            { r: 'Administrativ (bancă)', v: rezumat.adm_b },
            { r: 'Acordat împrumut', v: rezumat.imprumutAcordat },
            { r: 'Dobândă reținută', v: rezumat.dobandaRetinuta },
            { r: 'Dobândă eșalonată', v: rezumat.dobandaEsalonata },
            { r: 'Restituit împrumut (casă)', v: rezumat.imprumutRestituit_c },
            { r: 'Restituit împrumut (bancă)', v: rezumat.imprumutRestituit_b },
            { r: 'Încasat dif dobândă', v: rezumat.difDobanda },
            { r: 'Total zi încasări', v: rezumat.totalIncasariZi },
            { r: 'Sold cotizație', v: rezumat.totalCotizatie },
            { r: 'Total FAD', v: rezumat.totalAj },
            { r: 'Total Sănătate', v: '' }, // missing
            { r: 'Total Cultural', v: rezumat.totalCultural },
            { r: 'Total Administrativ', v: rezumat.totalAdm },
            { r: 'Împrumut acordat', v: rezumat.totalImprumutAcodat },
            { r: 'Sold împrumut', v: rezumat.totalImprumutRestituit },
            { r: 'Total General', v: '' },// missing
            { r: 'Sold cotizație', v: '' },// missing
            { r: 'Împrumut acordat', v: '' },// missing
            { r: 'Total FAD', v: '' },// missing
            { r: 'Total Sănătate', v: '' },// missing
            { r: 'Total Cultural', v: '' },// missing
            { r: 'Total Administrativ', v: '' },// missing
            { r: 'Sold împrumut', v: '' },// missing
            { r: 'Scoși din evidență', v: '' },// missing
        ];
        return result;
    },
    getRezumatCasa(rezumat) {
        let result = [
            { r: 'Cost carnet', v: rezumat.costCarnet },
            { r: 'Cotizație (casă)', v: rezumat.cotizatie_c },
            { r: 'Cotizație (bancă)', v: rezumat.cotizatie_b },
            { r: 'FAD (casă)', v: rezumat.fad_c },
            { r: 'FAD (bancă)', v: rezumat.fad_b },
            { r: 'Sănătate (casă)', v: rezumat.aj_c },
            { r: 'Sănătate (bancă)', v: rezumat.aj_b },
            { r: 'Cultural (casă)', v: rezumat.cultural_c },
            { r: 'Cultural (bancă)', v: rezumat.cultural_b },
            { r: 'Administrativ (casă)', v: rezumat.adm_c },
            { r: 'Administrativ (bancă)', v: rezumat.adm_b },
            { r: 'Acordat împrumut', v: rezumat.imprumutAcordat },
            { r: 'Dobândă reținută', v: rezumat.dobandaRetinuta },
            { r: 'Dobândă eșalonată', v: rezumat.dobandaEsalonata },
            { r: 'Restituit împrumut (casă)', v: rezumat.imprumutRestituit_c },
            { r: 'Restituit împrumut (bancă)', v: rezumat.imprumutRestituit_b },
            { r: 'Încasat dif dobândă', v: rezumat.difDobanda },
            { r: 'Total zi încasări', v: rezumat.totalIncasariZi },
        ];
        return result;
    },
}
export default contributorsFunctions;
