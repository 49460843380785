const contribuitorsSituationsColumnsConfig = {
    none: [],
    veziContabilitatea: [
        { field: 'id', headerName: 'Nrc', width: 60 },
        { field: 'zi', headerName: 'Zi', width: 30 },
        { field: 'luna', headerName: 'Luna', width: 30 },
        { field: 'an', headerName: 'An', width: 70 },
        { field: 'docType', headerName: 'Fel doc' },
        { field: 'docNr', headerName: 'Nr. doc' },
        { field: 'notes', headerName: 'Explicații' },
        { field: 'debit', headerName: 'Debit' },
        { field: 'credit', headerName: 'Credit' },
        { field: 'suma', headerName: 'Suma' },
        { field: 'tva', headerName: 'TVA' },
    ],
    rezumatulZilei: [
        { field: 'r', headerName: 'Rezumat', width: 300 },
        { field: 'v', headerName: 'Valoare' },
    ],
    încasăriPeZi: [
        { field: 'cod', headerName: 'Cod', width: 60 },
        { field: 'casa', headerName: 'Casa' },
        { field: 'nume', headerName: 'Nume și prenume' },
        { field: 'carnet', headerName: 'Carnet' },
        { field: 'cotizatie', headerName: 'Cotizație' },
        { field: 'fad', headerName: 'FAD' },
        { field: 'sanatate', headerName: 'Sănătate' },
        { field: 'cultural', headerName: 'Cultural' },
        { field: 'administrativ', headerName: 'Administrativ' },
        { field: 'restituit', headerName: 'Restituit' },
        { field: 'dobret', headerName: 'Dob. ret.' },
        { field: 'dobs', headerName: 'Dob. es.' },
        { field: 'difdob', headerName: 'Dif. Dob.' },
        { field: 'total', headerName: 'Total' },
        { field: 'imprumut', headerName: 'Împrumut' },
        { field: 'feldob', headerName: 'Fel dob.' },
        { field: 'nrc', headerName: 'Nrc.' },
        { field: 'nrch', headerName: 'Nr. ch.' },
    ],
    situațiaLunară: [
        { field: 'zi', headerName: 'Zi', width: 30 },
        { field: 'carnet', headerName: 'Carnet' },
        { field: 'cotiz_c', headerName: 'Cotiz c' },
        { field: 'cotiz_b', headerName: 'Cotiz b' },
        { field: 'fad_c', headerName: 'FAD c' },
        { field: 'fad_b', headerName: 'FAD b' },
        { field: 'san_c', headerName: 'Săn. c' },
        { field: 'san_b', headerName: 'Săn. b' },
        { field: 'cult_c', headerName: 'Cul. c' },
        { field: 'cult_b', headerName: 'Cul. b' },
        { field: 'adm_c', headerName: 'Adm. c' },
        { field: 'adm_b', headerName: 'Adm. b' },
        { field: 'imprumut', headerName: 'Împrumut' },
        { field: 'restImp_c', headerName: 'Rest împ. c' },
        { field: 'restImp_b', headerName: 'Rest împ. b' },
        { field: 'dobRet', headerName: 'Dob. ret.' },
        { field: 'dobEsal', headerName: 'Dob. eșal.' },
    ],
    situații: [
        { field: 'id', headerName: 'ID', width: 60 },
        { field: 'name', headerName: 'Nume', width: 150, },
        { field: 'address', headerName: 'Domiciliu', width: 300, },
        { field: 'phoneNumber', headerName: 'Telefon', },
        { field: 'cotisationAmmount', headerName: 'Sold cotiz', },
        { field: 'contributionPaymentDate', headerName: 'D. ultimei contrib', },
        { field: 'loanAmmount', headerName: 'Împr acordat', },
        { field: 'loanBalance', headerName: 'Sold împrumut', width: 110, },
        { field: 'lastLoanPaymentDate', headerName: 'D. ultimei rest', },
        { field: 'dataNasterii', headerName: 'D. nașterii', },
        { field: 'registrationDate', headerName: 'D. înscrierii', },
    ],
    restanțieri: [
        { field: 'cod', headerName: 'Cod', width: 60 },
        { field: 'nume', headerName: 'Numele', width: 130 },
        { field: 'dataImprumut', headerName: 'Data împrumut', width: 130 },
        { field: 'valoareImprumut', headerName: 'Val. împrumut' },
        { field: 'nrRate', headerName: 'Nr. rate', width: 60 },
        { field: 'valoareRata', headerName: 'Valoare Rată' },
        { field: 'soldImprumut', headerName: 'Sold Împrumut', width: 130 },
        { field: 'dataUltimeiRestituiri', headerName: 'Data ultimei rest', width: 130 },
        { field: 'zileRest', headerName: 'Zile rest.', width: 80 },
        { field: 'nrTelefon', headerName: 'Nr. Telefon' },
        { field: 'adresa', headerName: 'Adresa', width: 300 },
    ],
    cuPensie: [
        { field: 'numarCarnet', headerName: 'Carnet', width: 60 },
        { field: 'nume', headerName: 'Nume', width: 150, },
        { field: 'adresa', headerName: 'Domiciliu', width: 300, },
        { field: 'nrTelefon', headerName: 'Telefon', },
        { field: 'pensionAmmount', headerName: 'Pensie', },
    ],
    restDinAnul: [
        { field: 'id', headerName: 'ID', width: 60 },
        { field: 'nume', headerName: 'Nume', width: 150, },
        { field: 'adresa', headerName: 'Domiciliu', width: 300, },
        { field: 'soldCotiz', headerName: 'Sold cotiz.', },
    ],
}
export default contribuitorsSituationsColumnsConfig;