import React, { Component } from "react";
import env from "../env";
import config from "../config";
import contribuitorsSituationsColumnsConfig from './contribuitorsSituationsColumns';
import contributorsFunctions from "./contributorsGeneric";
import Swal from "sweetalert2";

import {
    Box,
    Button,
} from '@mui/material';
import {
    DataGrid,
} from '@mui/x-data-grid';
import {
    TextField
} from '@material-ui/core';

import '../../node_modules/font-awesome/css/font-awesome.min.css';

const situationsCategory = {
    none: 0,
    veziContabilitatea: 1,
    rezumatulZilei: 2,
    rezumatulZileiCasă: 3,
    încasăriPeZi: 4,
    încasăriPeZiCasă: 5,
    situațiaLunară: 6,
    veziRestanțieri: 10,
    veziCuPensie: 11,
    restDinAnul: 12,
};

export default class ContribuitorsLists extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loadingState: config.LoadingStates.loading,
            data: [],
            settings: {
                date: new Date().toISOString().substring(0, 10),
                restantieri: '',
                pensionMin: '',
                pensionMax: '',
            },
            situationsView: situationsCategory.none,
        };

        this.getLists = this.getLists.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.viewContability = this.viewContability.bind(this);
    }

    componentDidMount() {
        this.setState({
            ...this.state,
            loadingState: config.LoadingStates.loaded,
        });
    };

    getLists(toDisplay) {
        let { currentOperator } = this.props;
        console.log(toDisplay);
        let settings = this.state.settings;

        let endpoint = '';
        switch (toDisplay) {
            case situationsCategory.none:
                break;
            case situationsCategory.veziContabilitatea:
                endpoint = 'accounting';
                break;
            case situationsCategory.rezumatulZilei:
                endpoint = 'rezumat/' + settings.date;
                break;
            case situationsCategory.rezumatulZileiCasă:
                endpoint = 'rezumat/' + settings.date + '/' + currentOperator.casa;
                break;
            case situationsCategory.încasăriPeZi:
                endpoint = 'incasari/' + settings.date;
                break;
            case situationsCategory.încasăriPeZiCasă:
                endpoint = 'incasari/' + settings.date + '/' + currentOperator.casa;
                break;
            case situationsCategory.situațiaLunară:
                endpoint = 'monthly/' + settings.date;
                break;
            case situationsCategory.veziRestanțieri:
                if (!settings.restantieri) {
                    Swal.fire({
                        title: 'Eroare',
                        text: "Vă rugăm să introduceți numărul zilelor restanță!",
                        icon: 'error',
                    });
                    this.setState({
                        ...this.state,
                        loadingState: config.LoadingStates.loaded,
                        situationsView: situationsCategory.none,
                    });
                    return;
                }
                endpoint = 'restantieri/' + settings.restantieri;
                break;
            case situationsCategory.veziCuPensie:
                let min = settings.pensionMin;
                if (!min) { min = 0; }
                if (!settings.pensionMax) {
                    Swal.fire({
                        title: 'Eroare',
                        text: "Vă rugăm să introduceți pensia maximă!",
                        icon: 'error',
                    });
                    this.setState({
                        ...this.state,
                        loadingState: config.LoadingStates.loaded,
                        situationsView: situationsCategory.none,
                    });
                    return;
                }
                endpoint = 'pensie-intre/' + min + '/' + settings.pensionMax;
                break;
            case situationsCategory.restDinAnul:
                endpoint = 'restrantieri-pe-an/';
                break;
        }

        let url = env.ApiBaseUrl + 'situations/' + endpoint;

        fetch(url, {
            method: 'GET',
            headers: new Headers({
                'cache-control': "no-cache"
            })
        })
            .then(res => res.json())
            .then(
                (result) => {
                    if (result && toDisplay === situationsCategory.rezumatulZilei) {
                        result = contributorsFunctions.getRezumat(result);
                    }
                    if (result && toDisplay === situationsCategory.rezumatulZileiCasă) {
                        result = contributorsFunctions.getRezumatCasa(result);
                    }
                    if (result && result.length) {
                        if (!result[0].id) {
                            result = result.map((item, index) => ({ ...item, id: index + 1 }))
                        }
                    }
                    this.setState({
                        ...this.state,
                        loadingState: config.LoadingStates.loaded,
                        data: result,
                    })
                },
                (error) => {
                    console.log("Eroare: ", error);
                    this.setState({
                        ...this.state,
                        loadingState: config.LoadingStates.error,
                    })
                }
            );
    }

    handleChange = (event, location) => {
        let { settings } = this.state;
        settings[location] = event.target.value;

        this.setState({
            ...this.state,
            settings: settings,
        });
    };

    viewContability = (toDisplay) => {
        this.setState({
            ...this.state,
            situationsView: toDisplay,
            loadingState: config.LoadingStates.loading,
        });
        this.getLists(toDisplay);
    }

    render() {
        let { settings, situationsView, loadingState } = this.state;
        let { currentOperator } = this.props;

        const topPage =
            <form autoComplete="off" >
                <Button className="c-button" variant="outlined" color='secondary' onClick={() => this.props.showPage(config.DisplayingPage.view)}>Înapoi </Button>
                <Button className="c-button" variant="outlined" onClick={() => this.viewContability(situationsCategory.veziContabilitatea)}>Vizualizează contabilitatea </Button>

                <TextField
                    className="c-view-config"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    label="Data"
                    value={settings.date}
                    size="small"
                    onChange={e => this.handleChange(e, "date")} />
                <Button className="c-button" variant="outlined" onClick={() => this.viewContability(situationsCategory.rezumatulZilei)}>Rezumatul zilei </Button>
                <Button className="c-button" variant="outlined" onClick={() => this.viewContability(situationsCategory.rezumatulZileiCasă)}>Rezumatul zilei (nr.casă) </Button>
                <Button className="c-button" variant="outlined" onClick={() => this.viewContability(situationsCategory.încasăriPeZi)}>Încasări plăți/zi </Button>
                <Button className="c-button" variant="outlined" onClick={() => this.viewContability(situationsCategory.încasăriPeZiCasă)}>Încasări plăți/zi (nr. casă)</Button>
                <Button className="c-button" variant="outlined" onClick={() => this.viewContability(situationsCategory.situațiaLunară)}>Situația lunară </Button>

                <TextField
                    className="c-view-config"
                    label="Zile restanță"
                    value={settings.restantieri}
                    size="small"
                    onChange={e => this.handleChange(e, "restantieri")} />
                <Button className="c-button" variant="outlined" onClick={() => this.viewContability(situationsCategory.veziRestanțieri)}>Vezi restanțieri </Button>

                <TextField
                    className="c-view-config"
                    label="Pensie minimă"
                    value={settings.pensionMin}
                    size="small"
                    onChange={e => this.handleChange(e, "pensionMin")} />
                <TextField
                    className="c-view-config"
                    label="Pensie maximă"
                    value={settings.pensionMax}
                    size="small"
                    onChange={e => this.handleChange(e, "pensionMax")} />
                <Button className="c-button" variant="outlined" onClick={() => this.viewContability(situationsCategory.veziCuPensie)}>Vezi cu pensie </Button>

                <Button className="c-button" variant="outlined" onClick={() => this.viewContability(situationsCategory.restDinAnul)}>Vezi restanțieri la cotizații din ultimul an </Button>

                <br />
            </form>

        let columnsToDisplay = [];
        let extraTitle = '';

        switch (situationsView) {
            case situationsCategory.none:
                columnsToDisplay = contribuitorsSituationsColumnsConfig.none;
                extraTitle = '';
                break;
            case situationsCategory.veziContabilitatea:
                columnsToDisplay = contribuitorsSituationsColumnsConfig.veziContabilitatea;
                extraTitle = ' - vizualizează contabilitatea';
                break;
            case situationsCategory.rezumatulZilei:
                columnsToDisplay = contribuitorsSituationsColumnsConfig.rezumatulZilei;
                extraTitle = ' - rezumatul zilei ' + settings.date;
                break;
            case situationsCategory.rezumatulZileiCasă:
                columnsToDisplay = contribuitorsSituationsColumnsConfig.rezumatulZilei;
                extraTitle = ' - rezumatul zilei ' + settings.date + ', casa ' + currentOperator.casa;
                break;
            case situationsCategory.încasăriPeZi:
                columnsToDisplay = contribuitorsSituationsColumnsConfig.încasăriPeZi;
                extraTitle = ' - încasări - plăți / zi';
                break;
            case situationsCategory.încasăriPeZiCasă:
                columnsToDisplay = contribuitorsSituationsColumnsConfig.încasăriPeZi;
                extraTitle = ' - încasări - plăți / zi, casa ' + currentOperator.casa;;
                break;
            case situationsCategory.situațiaLunară:
                columnsToDisplay = contribuitorsSituationsColumnsConfig.situațiaLunară;
                extraTitle = ' - situația lunară';
                break;
            case situationsCategory.veziRestanțieri:
                columnsToDisplay = contribuitorsSituationsColumnsConfig.restanțieri;
                extraTitle = ' - Restanțieri de ' + settings.restantieri + 'zile';
                break;
            case situationsCategory.veziCuPensie:
                columnsToDisplay = contribuitorsSituationsColumnsConfig.cuPensie;
                let min = settings.pensionMin;
                if (!min) { min = 0; }
                extraTitle = ' - cu pensie între ' + min + ' și ' + settings.pensionMax;
                break;
            case situationsCategory.restDinAnul:
                columnsToDisplay = contribuitorsSituationsColumnsConfig.restDinAnul;
                let length = this.state.data && this.state.data.length;
                if (!length) { length = 0; }
                extraTitle = ' - restanțieri la cotizații din anul ' + settings.restantieriDinAnul + ' nr = ' + length;
                break;
            default:
                console.log(`Wrong ${situationsView}.`);
        }

        return (
            <div>
                <h1 className="title">Situații{extraTitle}</h1>
                {loadingState === config.LoadingStates.error &&
                    <div>
                        A apărut o eroare!
                        <br />
                        <Button className="c-button" variant="outlined" color='warning' onClick={this.props.logout}>Autentificați-vă din nou</Button>
                    </div>}
                {loadingState === config.LoadingStates.loading &&
                    <div>Se încarcă datele...</div>}
                {(loadingState === config.LoadingStates.loaded) && topPage}
                {loadingState === config.LoadingStates.loaded && situationsView !== situationsCategory.none && <Box sx={{ width: '100%' }}>
                    <DataGrid
                        rowHeight={25}
                        rows={this.state.data}
                        columns={columnsToDisplay}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 40,
                                },
                            },
                            sorting: {
                                sortModel: [{ field: 'name' }],
                            },
                        }}
                        pageSizeOptions={config.DataGrid.PageSizeOptions}
                    />
                </Box>}
            </div >
        );
    };
}