import React, { Component } from "react";
import env from "../env";
import config from "../config";
import {
    Box,
    Button,
    Divider,
    MenuItem,
    Modal,
    Stack,
    Select,
} from '@mui/material';
import Swal from "sweetalert2";
import { TextField } from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import { ModalClose } from '@mui/joy';

const editMode = { none: 0, edit: 1, add: 2, }
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default class MainOperators extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loadingState: config.LoadingStates.loading,
            currentOperator: {},
            editedOperator: {},
            operators: [],
            editMode: editMode.none,
            error: '',
        };

        this.initAdmin = this.initAdmin.bind(this);
        this.edit = this.edit.bind(this);
        this.create = this.create.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.getRoleDiv = this.getRoleDiv.bind(this);
        this.createOperator = this.createOperator.bind(this);
        this.updateConfig = this.updateConfig.bind(this);
        this.updatePass = this.updatePass.bind(this);
        this.isInvalidCreate = this.isInvalidCreate.bind(this);
        this.upsert = this.upsert.bind(this);
        this.isInvalidUserConfig = this.isInvalidUserConfig.bind(this);
        this.isInvalidPass = this.isInvalidPass.bind(this);
    }

    componentDidMount() {
        let { role } = this.props.currentOperator;
        if (role === 'admin') {
            this.initAdmin();
        }
        else {
            this.setState({
                ...this.state,
                loadingState: config.LoadingStates.loaded,
                editMode: editMode.edit,
                editedOperator: this.props.currentOperator,
            });
        }
    };

    initAdmin() {
        this.setState({
            ...this.state,
            loadingState: config.LoadingStates.loading,
        });

        let url = env.ApiBaseUrl + '/operators/all';
        fetch(url, {
            method: 'GET',
            headers: new Headers({
                'cache-control': "no-cache"
            })
        })
            .then(res => res.json())
            .then(
                (result) => {
                    if (result && result.length) {
                        this.setState({
                            ...this.state,
                            loadingState: config.LoadingStates.loaded,
                            operators: result,
                            editMode: editMode.none,
                        })
                    } else {
                        this.setState({
                            ...this.state,
                            loadingState: config.LoadingStates.error,
                        })
                    }
                },
                (error) => {
                    console.log("Eroare: ", error);
                    this.setState({
                        ...this.state,
                        loadingState: config.LoadingStates.error,
                    })
                }
            );
    }

    edit(operator) {
        this.setState({
            ...this.state,
            editedOperator: {
                id: operator.id,
                name: operator.name,
                userName: operator.userName,
                role: operator.role,
            },
            editMode: editMode.edit,
            error: '',
        });
    }

    isInvalidUserConfig() {
        let { editedOperator } = this.state;
        let result = '';
        if (!editedOperator.role) {
            if (result.length) { result += '; ' }
            result += 'rol'
        }
        if (!editedOperator.userName) {
            if (result.length) { result += '; ' }
            result += 'utilizator'
        }
        if (!editedOperator.name) {
            if (result.length) { result += '; ' }
            result += 'nume'
        }
        return result;
    }

    isInvalidPass() {
        let { editedOperator } = this.state;
        let result = '';
        if (!editedOperator.pass) {
            if (result.length) { result += '; ' }
            result += 'parolă'
        }
        if (editedOperator.pass !== editedOperator.pass2) {
            if (result.length) { result += '; ' }
            result += 'confirmarea parolei (nu coincide)'
        }
        return result;
    }

    isInvalidCreate() {
        let result = this.isInvalidUserConfig();
        let isInvalidPass = this.isInvalidPass();
        if (isInvalidPass.length && result.length) { result += '; ' }
        result += isInvalidPass;
        return result;
    }

    createOperator() {
        let isInvalidInputResult = this.isInvalidCreate();
        if (isInvalidInputResult) {
            this.setState({
                ...this.state,
                error: isInvalidInputResult,
            });
            return;
        }
        let { editedOperator } = this.state;
        let toSend = {
            userName: editedOperator.userName,
            name: editedOperator.name,
            password: editedOperator.pass,
            role: editedOperator.role,
        };
        this.upsert(toSend);
    }

    updateConfig() {
        let isInvalidInputResult = this.isInvalidUserConfig();
        if (isInvalidInputResult) {
            this.setState({
                ...this.state,
                error: isInvalidInputResult,
            });
            return;
        }
        let { editedOperator } = this.state;
        let toSend = {
            id: editedOperator.id,
            userName: editedOperator.userName,
            name: editedOperator.name,
            role: editedOperator.role,
        };
        this.upsert(toSend);
    }

    updatePass() {
        let isInvalidInputResult = this.isInvalidCreate();
        if (isInvalidInputResult) {
            this.setState({
                ...this.state,
                error: isInvalidInputResult,
            });
            return;
        }
        let { editedOperator } = this.state;
        let toSend = {
            id: editedOperator.id,
            userName: editedOperator.userName,
            name: editedOperator.name,
            role: editedOperator.role,
            password:editedOperator.pass,
        };
        this.upsert(toSend);
    }

    create() {
        this.setState({
            ...this.state,
            editedOperator: {},
            editMode: editMode.add,
            error: '',
        });
    }

    upsert(toSend) {
        this.setState({
            ...this.state,
            loadingState: config.LoadingStates.loading
        });
        let url = env.ApiBaseUrl + "operators";
        fetch(url, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json',
                'cache-control': "no-cache"
            }),
            body: JSON.stringify(toSend),

        })
            .then(
                (result) => {
                    if (result.status === 200) {
                        Swal.fire({
                            title: 'Salvare cu success',
                            text: 'Operatorul a fost salvat cu success!',
                            icon: 'success',
                        });
                        this.initAdmin();
                    } else {
                        Swal.fire({
                            title: 'Eroare',
                            text: 'Operatorul nu a putut fi salvat!',
                            icon: 'error',
                        });
                    }
                },
                () => {
                    Swal.fire({
                        title: 'Eroare',
                        text: 'Operatorul nu a putut fi salvat!',
                        icon: 'error',
                    });
                }
            );
    }

    handleCloseModal(event, reason) {
        if (reason && reason === "backdropClick")
            return;
        let { role } = this.props.currentOperator;
        if (role === 'admin') {
            this.setState({
                ...this.state,
                editedOperator: {},
                editMode: editMode.none,
            });
        } else {
            this.props.showPage(config.DisplayingPage.view);
        }
    }

    handleChange = (event, location) => {
        let { editedOperator } = this.state;
        editedOperator[location] = event.target.value;

        this.setState({
            ...this.state,
            editedOperator: editedOperator,
        });
    };

    getRoleDiv(role) {
        let result = (role === 'admin') ? 'Administrator' : 'Operator';
        return <div className="blue">{result}</div>
    }

    render() {
        let { editedOperator, error } = this.state;
        let { role, id } = this.props.currentOperator;
        let operatorText = (role === 'admin') ? "Administrează operatori" : "Administrează contul";
        const isEditingSelf = editedOperator.id === id;

        const OperatorsColumns = [
            { field: 'id', headerName: 'ID', width: 30 },
            { field: 'userName', headerName: 'Utilizator', width: 150 },
            { field: 'name', headerName: 'Nume', width: 150 },
            {
                field: 'role', headerName: 'Rol', width: 150,
                renderCell: (params) => {

                    return (
                        <Stack direction="row">
                            {params.row.role === 'admin' ? 'administrator' : 'operator'}
                        </Stack>

                    );
                },
            },
            {
                field: 'action',
                headerName: 'Operație',
                width: 150,
                sortable: false,
                disableClickEventBubbling: true,

                renderCell: (params) => {
                    return (
                        <Stack direction="row" spacing={2}>
                            <Button variant="outlined" size="small" onClick={() => this.edit(params.row)}>Editează</Button>
                        </Stack>
                    );
                },
            }
        ]

        const adminArea = <Box sx={{ height: 380, width: '100%' }}>
            <DataGrid
                rowHeight={25}
                rows={this.state.operators}
                columns={OperatorsColumns}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 40,
                        },
                    },
                    sorting: {
                        sortModel: [{ field: 'userName' }],
                    },
                }}
                pageSizeOptions={config.DataGrid.PageSizeOptions}
                />
        </Box>

        const addEditUser = <Modal
            open={this.state.editMode === editMode.edit || this.state.editMode === editMode.add}
            onClose={this.handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <ModalClose onClick={this.handleCloseModal} />
                <Divider className="blue" textAlign="left">Detalii cont</Divider>
                <div className="c-manager-div">
                    Rol: &nbsp;&nbsp;
                    {isEditingSelf ? this.getRoleDiv(editedOperator.role) :
                        <Select

                            className="c-manager-status"
                            value={editedOperator.role}
                            onChange={e => this.handleChange(e, "role")}
                        >
                            <MenuItem value='admin'>&nbsp;Administrator</MenuItem>
                            <MenuItem value='user'>&nbsp;Operator</MenuItem>
                        </Select>}
                </div>
                <br />
                {this.state.editMode === editMode.edit &&
                    <div>Utilizator: &nbsp;&nbsp; <div className="blue">{editedOperator.userName}</div></div>
                }
                {this.state.editMode === editMode.add &&
                    <div>
                        <TextField
                            className="c-manager"
                            label="Utilizator"
                            value={editedOperator.userName}
                            maxLength={100}
                            size="small"
                            onChange={e => this.handleChange(e, "userName")} />
                        <br />
                    </div>
                }
                <TextField
                    className="c-manager"
                    label="Nume"
                    value={editedOperator.name}
                    maxLength={100}
                    size="small"
                    onChange={e => this.handleChange(e, "name")} />
                <br />
                {this.state.editMode === editMode.edit &&
                    <Button className="c-button" variant="outlined" size="small" onClick={this.updateConfig}>Actualizează detaliile</Button>
                }
                <br />
                <br />
                <Divider className="blue" textAlign="left">Parolă</Divider>
                <TextField
                    className="c-manager"
                    type="password"
                    label="Parola"
                    value={editedOperator.pass}
                    maxLength={100}
                    size="small"
                    onChange={e => this.handleChange(e, "pass")} />
                <br />
                <TextField
                    className="c-manager"
                    type="password"
                    label="Confirmă parola"
                    value={editedOperator.pass2}
                    maxLength={100}
                    size="small"
                    onChange={e => this.handleChange(e, "pass2")} />
                <br />
                {this.state.editMode === editMode.edit &&
                    <Button className="c-button" variant="outlined" size="small" onClick={this.updatePass}>Schimbă parola</Button>
                }

                {this.state.editMode === editMode.add &&
                    <Button className="c-button" variant="outlined" size="small" onClick={this.createOperator}>Crează operatorul</Button>
                }
                <br />
                {error && <div className="red">Eroare: Vă rugăm să introduceți {error}</div>}
            </Box>
        </Modal>

        return (
            <div>
                <h1 className="title">{operatorText}</h1>
                {role === 'admin' && <Button className="c-button" variant="outlined" color='secondary' onClick={() => this.props.showPage(config.DisplayingPage.view)}>Înapoi </Button>}
                {role === 'admin' && <Button className="c-button" variant="outlined" onClick={this.create}>Crează un operator</Button>}
                {role === 'admin' && <Button className="c-button" variant="outlined" color='warning' onClick={this.props.logout}>Deconectați-vă</Button>}
                {this.state.loadingState === config.LoadingStates.loading && <div>Se încarcă datele...</div>}
                {this.state.loadingState === config.LoadingStates.loaded && role === 'admin' && adminArea}
                {addEditUser}
                <br />
            </div>
        );
    };
}